@import "boxicons";
body {
  background-color: darken(#1b1b1e, 2%);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #d8dbe2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  transition: all 0.4s ease;
}

a {
  color: darken(#d8dbe2, 20%);
  text-decoration: none;

  &:hover {
    color: lighten(#d8dbe2, 20%);
  }
}

p {
  margin: 0;
  line-height: 1.5;

  &.note {
    font-size: 12px;
  }
}

* + p {
  margin-top: 16px;
}
